<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
      <el-row :gutter="20">
        <el-col
          :span="12"
          class="statistics text-center"
        >
          <div class="head">
            稽核作业总数(个)
          </div>
          <div class="body d-flex">
            <div class="flex1">
              <div>总作业数</div>
              <div>{{ workRunResult.workTotal }}</div>
            </div>
            <div class="flex1">
              <div>已运行作业数</div>
              <div>{{ workRunResult.workRunNum }}</div>
            </div>
            <div class="flex1">
              <div>未运行作业数</div>
              <div>{{ workRunResult.workNotRunNum }}</div>
            </div>
          </div>
        </el-col>

        <el-col
          :span="12"
          class="statistics text-center"
        >
          <div class="head">
            稽核总数据量(条)
          </div>
          <div class="body d-flex">
            <div class="flex1">
              <div>总量</div>
              <div>{{ dataQualityResult.dataTotal }}</div>
            </div>
            <div class="flex1">
              <div>优质数据量</div>
              <div>{{ dataQualityResult.highQualityNum }}</div>
            </div>
            <div class="flex1">
              <div>问题数据量</div>
              <div>{{ dataQualityResult.problemQualityNum }}</div>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        class="charts"
      >
        <el-col
          :span="12"
          class="text-center"
        >
          <span>各规则优质数据量</span>
          <div id="myChart1" />
        </el-col>

        <el-col
          :span="12"
          class="text-center"
        >
          <span>各规则问题数据量</span>
          <div id="myChart2" />
        </el-col>
        <el-col
          :span="24"
          class="text-center"
        >
          <span>最近7天数据质量趋势图</span>
          <div id="myChart3" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb'

export default {
  name: 'DashboardPage',
  components: {
    BreadCrumb,
  },
  data() {
    return {
      workRunResult: {
        workTotal: 0,
        workRunNum: 0,
        workNotRunNum: 0,
      },
      dataQualityResult: {
        dataTotal: 0,
        highQualityNum: 0,
        problemQualityNum: 0,
      },
    }
  },
  mounted: function () {
    this.InitializationText()
  },
  methods: {
    InitializationText() {
      const that = this

      this.$axios.GET('/api/v1/core/statistics/getWorkRunResult').then(function (res) {
        that.workRunResult = res.data
      })
      this.$axios.GET('/api/v1/core/statistics/getDataQualityResult').then(function (res) {
        that.dataQualityResult = res.data
      })
      this.$axios.GET('/api/v1/core/statistics/getRuleDataQualityResult').then(function (res) {
        that.drawRulePie1Chart(res.data)
        that.drawRulePie2Chart(res.data)
      })
      this.$axios.GET('/api/v1/core/statistics/recentSevenDaysDataQualityTrendData').then(function (res) {
       
        that.drawLineChart(res.data)
      })
    },
    drawRulePie1Chart(data) {
     
      const _this = this
      document.getElementById('myChart1').setAttribute('_echarts_instance_', '');
      let myChart1 = _this.$echarts.init(document.getElementById('myChart1'))

      myChart1.clear()
      var option1 = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 10,
          top: 20,
          bottom: 20,
          data: data[0],
        },
        series: [
          {
            name: '函数',
            type: 'pie',
            radius: '55%',
            center: ['40%', '50%'],
            data: data[1],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(180, 180, 180, 0.2)',
              },
            },
          },
        ],
      }

      myChart1.setOption(option1,true)
    },
    drawRulePie2Chart(data) {
      const _this = this
      document.getElementById('myChart2').setAttribute('_echarts_instance_', '');

      let myChart2 = _this.$echarts.init(document.getElementById('myChart2'))

      myChart2.clear()
      const option2 = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 10,
          top: 20,
          bottom: 20,
          data: data[0],
        },
        series: [
          {
            name: '函数',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['40%', '50%'],
            data: data[2],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(180, 180, 180, 0.2)',
              },
            },
          },
        ],
      }

      myChart2.setOption(option2,true)
    },
    drawLineChart(data) {
      const _this = this
      document.getElementById('myChart3').setAttribute('_echarts_instance_', '');
      let myChart3 = _this.$echarts.init(document.getElementById('myChart3'))
      myChart3.clear()
    const option3 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: ['总量', '优质数据量', '问题数据量'],
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: data[0],
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '总量',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series',
            },
            data: data[1],
          },
          {
            name: '优质数据量',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series',
            },
            data: data[2],
          },
          {
            name: '问题数据量',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series',
            },
            data: data[3],
          },
        ],
      }
      myChart3.setOption(option3,true)
    },
  },
}
</script>

<style>
.statistics .head {
  background: #93c1f0;
  height: 30px;
  line-height: 30px;
  color: #352e2e;
}

.statistics .body {
  background: #f5f5f5;
}

.statistics .body .flex1 > div:nth-child(1) {
  margin: 8px 0;
}

.statistics .body .flex1 > div:nth-child(2) {
  margin-bottom: 15px;
  color: red;
  font-size: 10px;
}

.charts .el-col {
  position: relative;
}

.charts .el-col > span {
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 27px;
  z-index: 99;
}

.charts .el-col > div {
  background: #f6f6f6;
  height: 245px;
  margin-top: 20px;
  padding: 10px 5px;
}

.table_div {
  font-weight: 600;
  margin-left: 10px;
  margin-top: 20px;
}
</style>
